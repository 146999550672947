import React, { FunctionComponent } from "react"
import ResponsiveLink, { LinkDisplay } from "./ResponsiveLink"
import { ComponentNavigationLink } from "../../../types/generated/strapi"
import { LinkStyle } from "./AnyLink"
import optionalAnimationAttributes from "../../../utils/optionalAnimationAttributes"

type DoubleCTAProps = {
  primary?: ComponentNavigationLink
  secondary?: ComponentNavigationLink
  /*
   * AOS Animation Value, fade-up, fade-down, etc.
   */
  animationType?: string
  /**
   * Whether or not to use the default AOS Animation
   */
  useDefaultAnimation?: boolean
  /**
   * Any additional delay to be applied to the animations,
   * useful if part of an animated parent component
   */
  animationDelayOffset?: number
}
const DoubleCTA: FunctionComponent<DoubleCTAProps> = ({
  primary,
  secondary,
  animationType = undefined,
  animationDelayOffset = 0,
  useDefaultAnimation = false,
}) => {
  if (useDefaultAnimation) animationType = "fade-right"

  return (
    <>
      <span
        className="inline-block"
        {...optionalAnimationAttributes(
          animationType,
          animationDelayOffset + 100
        )}
      >
        <ResponsiveLink
          to={primary?.Link || "#"}
          linkTextDesktop={primary?.TextDesktop}
          linkTextMobile={primary?.TextMobile}
          clickClassDesktop={primary?.ClickClassDesktop}
          clickClassMobile={primary?.ClickClassMobile}
          linkStyle={LinkStyle.PrimaryButton}
          desktopVisibleStyle={LinkDisplay.inlineBlock}
          mobileVisibleStyle={LinkDisplay.inlineBlock}
          className="mt-s"
        />
      </span>

      <span
        className="inline-block"
        {...optionalAnimationAttributes(animationType, animationDelayOffset)}
      >
        <ResponsiveLink
          to={secondary?.Link || "#"}
          linkTextDesktop={secondary?.TextDesktop}
          linkTextMobile={secondary?.TextMobile}
          clickClassDesktop={secondary?.ClickClassDesktop}
          clickClassMobile={secondary?.ClickClassMobile}
          linkStyle={LinkStyle.SecondaryButton}
          desktopVisibleStyle={LinkDisplay.inlineBlock}
          mobileVisibleStyle={LinkDisplay.inlineBlock}
          className="mt-xxs"
        />
      </span>
    </>
  )
}

export default DoubleCTA
