export enum WBFontStyle {
  HeadingXXL = "wb2-type-heading-xxl",
  HeadingXL = "wb2-type-heading-xl",
  HeadingL = "wb2-type-heading-l",
  HeadingM = "wb2-type-heading-m",
  HeadingS = "wb2-type-heading-s",
  HeadingXS = "wb2-type-heading-xs",
  CopyStrong = "wb2-type-copy-strong",
  Copy = "wb2-type-copy",
  CopySecondary = "wb2-type-copy-secondary",
  CopyTertiary = "wb2-type-copy-tertiary",
  Hint = "wb2-type-hint",
  HintStrong = "wb2-type-hint-strong",
  Button = "wb2-type-button",
  ButtonSecondary = "wb2-type-button-secondary",
  Label = "wb2-type-label",
}
