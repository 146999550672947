import facebook from "wb2-assets/dist/icons/facebook.svg"
import React, { FunctionComponent } from "react"
import AnyLink from "../../../generic/AnyLink/AnyLink"

export type FacebookProps = {
  url?: string
}

const Facebook: FunctionComponent<FacebookProps> = ({ url }) => {
  return (
    <AnyLink to={url}>
      <svg
        className="h-s mr-xs hover:text-wb-color-amg transition-1/5"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <path d="M20.786 0H1.214C.543 0 0 .543 0 1.214v19.572C0 21.456.543 22 1.214 22h10.537v-8.52H8.884v-3.32h2.867V7.712c0-2.842 1.735-4.389 4.27-4.389 1.214 0 2.258.09 2.562.131v2.97l-1.758.001c-1.379 0-1.645.655-1.645 1.616v2.12h3.288l-.428 3.32h-2.86V22h5.606c.67 0 1.214-.544 1.214-1.214V1.214C22 .543 21.456 0 20.786 0z" />
      </svg>
    </AnyLink>
  )
}

export default Facebook
