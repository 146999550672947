import React, { FunctionComponent } from "react"
import AnyLink from "../../../generic/AnyLink/AnyLink"

export type TwitterProps = {
  url?: string
}

const Twitter: FunctionComponent<TwitterProps> = ({ url }) => {
  return (
    <AnyLink to={url}>
      <svg
        className="h-s mr-xs hover:text-wb-color-amg transition-1/5"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 21.13"
      >
        <path d="M26 2.501a10.68 10.68 0 0 1-3.064.84A5.35 5.35 0 0 0 25.281.39a10.683 10.683 0 0 1-3.387 1.294A5.329 5.329 0 0 0 18.001 0a5.334 5.334 0 0 0-5.196 6.55A15.143 15.143 0 0 1 1.81.976a5.314 5.314 0 0 0-.722 2.682c0 1.851.942 3.483 2.373 4.44a5.32 5.32 0 0 1-2.416-.667v.067a5.337 5.337 0 0 0 4.279 5.23 5.34 5.34 0 0 1-2.409.091 5.342 5.342 0 0 0 4.983 3.705 10.702 10.702 0 0 1-6.625 2.283c-.431 0-.855-.025-1.272-.074a15.102 15.102 0 0 0 8.177 2.396c9.812 0 15.177-8.128 15.177-15.177 0-.231-.005-.461-.015-.69A10.861 10.861 0 0 0 26 2.501z" />
      </svg>
    </AnyLink>
  )
}

export default Twitter
