import React, { FunctionComponent } from "react"
import AnyLink from "../../../generic/AnyLink/AnyLink"

export type YoutubeProps = {
  url?: string
}

const Youtube: FunctionComponent<YoutubeProps> = ({ url }) => {
  return (
    <AnyLink to={url}>
      <svg
        className="h-s mr-xs hover:text-wb-color-amg transition-1/5"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31 21.803"
      >
        <path d="M19.584 11.118l-.001-.001 1.031-.534-1.03.535" />
        <path d="M12.3 14.923l-.001-8.71 8.376 4.37-8.375 4.34zM30.69 4.704s-.303-2.136-1.232-3.077C28.279.392 26.958.386 26.352.314 22.014 0 15.507 0 15.507 0h-.013S8.987 0 4.649.314c-.607.072-1.928.078-3.107 1.313C.612 2.567.31 4.704.31 4.704S0 7.212 0 9.721v2.352c0 2.509.31 5.017.31 5.017s.302 2.136 1.232 3.077c1.179 1.235 2.728 1.196 3.418 1.325 2.48.238 10.54.311 10.54.311s6.514-.01 10.852-.323c.606-.072 1.927-.078 3.106-1.313.93-.941 1.232-3.077 1.232-3.077s.31-2.509.31-5.017V9.721c0-2.509-.31-5.017-.31-5.017z" />
      </svg>
    </AnyLink>
  )
}

export default Youtube
