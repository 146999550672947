const optionalAnimationAttributes = (
  animationType?: string,
  animationDelay?: number
) => {
  return (
    animationType && {
      "data-aos": animationType,
      "data-aos-delay": animationDelay,
    }
  )
}

export default optionalAnimationAttributes
