import { useStaticQuery, graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import Footer from "../../sections/Footer/Footer"
import {
  Footer as FooterModel,
  SocialMedia,
  Legal,
} from "../../../types/generated/strapi"
import { LegalLinks } from "../../../types/SiteMetaData"

type FooterLayoutData = {
  site: {
    siteMetadata: {
      title?: string
      legalLinks?: {
        cookies?: string
        dataProtection?: string
        legalNotice?: string
        provider?: string
      }
    }
  }
  strapiGqlSource: {
    footers?: FooterModel[]
    socialMedia?: SocialMedia
    legal?: Legal
  }
}

const FooterLayout: FunctionComponent = () => {
  const data: FooterLayoutData = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          legalLinks {
            cookies
            dataProtection
            legalNotice
          }
        }
      }
      strapiGqlSource {
        footers {
          ...FooterFragement
        }
        socialMedia {
          ...SocialIconsFragment
        }
        legal {
          CookiesUrl
          DataProtectionUrl
          LegalNoticeUrl
          ProviderUrl
        }
      }
    }
  `)

  const strapiLegalData = data?.strapiGqlSource?.legal
  const configLegalData = data?.site?.siteMetadata?.legalLinks

  const legal: LegalLinks = {
    cookies: strapiLegalData?.CookiesUrl || configLegalData?.cookies || "#",
    dataProtection:
      strapiLegalData?.DataProtectionUrl ||
      configLegalData?.dataProtection ||
      "#",
    legalNotice:
      strapiLegalData?.LegalNoticeUrl || configLegalData?.legalNotice || "#",
    provider: strapiLegalData?.ProviderUrl || configLegalData?.provider || "#",
  }

  return (
    <div className="layout">
      <Footer
        socialIcons={data?.strapiGqlSource.socialMedia}
        sections={data?.strapiGqlSource?.footers?.filter(
          foot => foot.IsActive == true
        )}
        legalLinks={legal}
      />
    </div>
  )
}
export default FooterLayout

export const footerFragment = graphql`
  fragment FooterFragement on StrapiGQLSource_Footer {
    IsActive
    Links {
      IsActive
      Link
      TextDesktop
      TextMobile
      _id
      id
    }
    Name
    _id
    id
  }
`
