export enum WBColorStyle {
    Highlight = "wb-color-highlight",
    HighlightHover = "wb-color-highlighthover",
    HighlightPressed = "wb-color-highlightpressed",
    HighlightOutlineDark = "wb-color-highlightoutline-dark",
    HighlightOutlineLight = "wb-color-highlightoutline-light",
    White = "wb-color-white",
    Smoke = "wb-color-smoke",
    Moon = "wb-color-moon",
    Asphalt = "wb-color-asphalt",
    Silver = "wb-color-silver",
    Nobel = "wb-color-nobel",
    Boulder = "wb-color-boulder",
    Gunmetal = "wb-color-gunmetal",
    Obsidian = "wb-color-obsidian",
    Mineshaft = "wb-color-mineshaft",
    Black = "wb-color-black",
    Ok = "wb-color-ok",
    Caution = "wb-color-caution",
    Error = "wb-color-error",
    Co2a = "wb-color-co2a",
    Co2b = "wb-color-co2b",
    Co2c = "wb-color-co2c",
    Co2d = "wb-color-co2d",
    Co2e = "wb-color-co2e",
    Co2f = "wb-color-co2f",
    Co2g = "wb-color-co2g",
    Amg = "wb-color-amg"
  }
  

export enum WBColorBGStyle {
  Highlight = "wb-color-highlight-bg",
  HighlightHover = "wb-color-highlighthover-bg",
  HighlightPressed = "wb-color-highlightpressed-bg",
  HighlightOutlineDark = "wb-color-highlightoutline-dark-bg",
  HighlightOutlineLight = "wb-color-highlightoutline-light-bg",
  White = "wb-color-white-bg",
  Smoke = "wb-color-smoke-bg",
  Moon = "wb-color-moon-bg",
  Asphalt = "wb-color-asphalt-bg",
  Silver = "wb-color-silver-bg",
  Nobel = "wb-color-nobel-bg",
  Boulder = "wb-color-boulder-bg",
  Gunmetal = "wb-color-gunmetal-bg",
  Obsidian = "wb-color-obsidian-bg",
  Mineshaft = "wb-color-mineshaft-bg",
  Black = "wb-color-black-bg",
  Ok = "wb-color-ok-bg",
  Caution = "wb-color-caution-bg",
  Error = "wb-color-error-bg",
  Co2a = "wb-color-co2a-bg",
  Co2b = "wb-color-co2b-bg",
  Co2c = "wb-color-co2c-bg",
  Co2d = "wb-color-co2d-bg",
  Co2e = "wb-color-co2e-bg",
  Co2f = "wb-color-co2f-bg",
  Co2g = "wb-color-co2g-bg",
  Amg = "wb-color-amg-bg"
}


