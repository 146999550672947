import React, { FunctionComponent } from "react"
import optionalAnimationAttributes from "../../../utils/optionalAnimationAttributes"
import { ENUM_COMPONENTCONTENTTITLE_HEADINGSIZE as HeadingSize } from "../../../types/generated/strapi"

type ResolveTitleProps = {
  text?: string

  animationType?: string

  animationDelayOffset?: number

  textStyle?: string

  heading?: HeadingSize
}

const ResolveTitle: FunctionComponent<ResolveTitleProps> = ({
  text,
  animationType,
  animationDelayOffset,
  textStyle,
  heading = HeadingSize.HeadingL,
}) => {
  const El =
    heading === HeadingSize.HeadingXXL
      ? "h1"
      : heading === HeadingSize.HeadingXL
      ? "h2"
      : heading === HeadingSize.HeadingL
      ? "h3"
      : heading === HeadingSize.HeadingM
      ? "h4"
      : heading === HeadingSize.HeadingS
      ? "h5"
      : "h6"

  return (
        <El
          {...optionalAnimationAttributes(
            animationType,
            animationDelayOffset && animationDelayOffset + 100
          )}
          className={`title__text ${textStyle}`}
        >
          {text}
        </El>
  )
}

export default ResolveTitle
 