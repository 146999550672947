import "./Logo.css"
import React, { FunctionComponent } from "react"
import Icon from "./Icons/amg-logo-full.svg"
import Menu from "wb2-assets/dist/icons/menu.svg"
import AnyLink from "../../../generic/AnyLink/AnyLink"

type LogoProps = {
  toggleMenuState: Function
}

const Logo: FunctionComponent<LogoProps> = ({ toggleMenuState }) => {
  return (
    <div className="logo">
      <AnyLink to="/">
        <img className="logo__image" src={Icon} alt="" />{" "}
      </AnyLink>
      <button className="logo__menu-button" onClick={_ => toggleMenuState()}>
        <img className="logo__menu-icon" src={Menu} alt="" />
      </button>
    </div>
  )
}
export default Logo
