import React, { FunctionComponent } from "react"
import AnyLink from "../../../generic/AnyLink/AnyLink"

export type PinterestProps = {
  url?: string
}

const Pinterest: FunctionComponent<PinterestProps> = ({ url }) => {
  return (
    <AnyLink to={url}>
      <svg
        className="h-s mr-xs  hover:text-wb-color-amg  transition-1/5"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <path d="M11 0C4.925 0 0 4.925 0 11c0 4.66 2.899 8.64 6.991 10.243-.096-.87-.183-2.205.038-3.155.2-.858 1.29-5.468 1.29-5.468s-.329-.659-.329-1.633c0-1.53.887-2.671 1.99-2.671.938 0 1.392.705 1.392 1.55 0 .944-.601 2.355-.911 3.662-.259 1.095.549 1.988 1.629 1.988 1.955 0 3.458-2.061 3.458-5.037 0-2.634-1.892-4.475-4.595-4.475-3.13 0-4.967 2.348-4.967 4.774 0 .945.364 1.959.819 2.51.09.109.103.204.076.315-.084.347-.269 1.095-.306 1.247-.048.201-.159.244-.368.147-1.374-.64-2.233-2.648-2.233-4.261 0-3.47 2.521-6.657 7.268-6.657 3.816 0 6.781 2.719 6.781 6.353 0 3.791-2.39 6.842-5.708 6.842-1.115 0-2.162-.579-2.521-1.263 0 0-.552 2.1-.685 2.615-.248.955-.919 2.153-1.367 2.884 1.03.318 2.124.49 3.258.49 6.075 0 11-4.925 11-11S17.075 0 11 0z" />
      </svg>
    </AnyLink>
  )
}

export default Pinterest
