import React, { FunctionComponent } from "react"

type WBGridRow = {
  className?: string
  /**
   * Should content from the new row be pushed to the next line. default true
   */
  isClearLeft?: boolean
}

const WBGridRow: FunctionComponent<WBGridRow> = ({
  children,
  className = "",
  isClearLeft = true,
}) => (
  <div
    className={`wb-content-grid__row ${className} ${
      isClearLeft ? "clear-left" : ""
    }`}
  >
    {children}
  </div>
)
export default WBGridRow
