import { WBFontStyle } from "../../../types/Fonts"
import { ENUM_COMPONENTCONTENTTITLE_HEADINGSIZE as HeadingSize } from "../../../types/generated/strapi"

const tOWHeadingSize = (
  headingSize: HeadingSize
): WBFontStyle => {
  switch (headingSize) {
    case HeadingSize.HeadingXXL:
      return WBFontStyle.HeadingXXL

    case HeadingSize.HeadingXL:
      return WBFontStyle.HeadingXL

    case HeadingSize.HeadingM:
      return WBFontStyle.HeadingM

    case HeadingSize.HeadingS:
      return WBFontStyle.HeadingS

    case HeadingSize.HeadingXS:
      return WBFontStyle.HeadingXS

    default:
      return WBFontStyle.HeadingL
  }
}

export default tOWHeadingSize
