import { useStaticQuery, graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import {
  Footer as FooterModel,
  Navigation as NavigationModel,
} from "../../../types/generated/strapi"
import Header from "../../sections/Header/Header"

type HeadertLayoutData = {
  strapiGqlSource: {
    navigations?: NavigationModel[]
  }
}

const HeaderLayout: FunctionComponent = () => {
  const data: HeadertLayoutData = useStaticQuery(graphql`
    query NavigationQuery {
      strapiGqlSource {
        navigations {
          ...NavigationFragment
        }
      }
    }
  `)

  return (
    <Header
      navSections={data?.strapiGqlSource?.navigations?.filter(
        nav => nav?.IsActive == true
      )}
    />
  )
}
export default HeaderLayout

export const navigationFragment = graphql`
  fragment NavigationFragment on StrapiGQLSource_Navigation {
    IsActive
    Links {
      IsActive
      Link
      TextDesktop
      TextMobile
      _id
      id
    }
    Name
    _id
    id
  }
`
