import "./AnyLink.css"
import React, { FunctionComponent, ReactNode } from "react"
import Link from "gatsby-link"
//import * as Icons from "wb2-assets/dist/icon.enum"
import ArrowRight from "wb2-assets/dist/icons/arrow-right.svg"
import { graphql } from "gatsby"

/**
 * Style options for links. WhiteUnderline is default
 */
export enum LinkStyle {
  Unstyled = "",
  WhiteUnstyled = "text-wb-color-white no-underline",
  WhiteUnderline = "text-wb-color-white underline",
  WhiteWithChevron = "text-wb-color-white no-underline chevron whitespace-no-wrap",
  BlackUnstyled = "text-wb-color-obsidian no-underline",
  BlackUnderline = "text-wb-color-obsidian underline",
  BlackWithChevron = "text-wb-color-obsidian no-underline chevron whitespace-no-wrap",
  PrimaryButton = "link--primary inline-block bg-wb-color-amg text-wb-color-white no-underline hover:shadow whitespace-no-wrap",
  SecondaryButton = "link--secondary inline-block bg-wb-color-white no-underline text-wb-color-obsidian hover:shadow hover:bg-wb-color-amg hover:text-wb-color-white hover:border-0 whitespace-no-wrap",
  SecondaryNoBorderButton = "link--secondary-noborder inline-block bg-wb-color-white no-underline text-wb-color-obsidian hover:shadow hover:bg-wb-color-amg hover:text-wb-color-white",
  WhiteTertiaryButton = "link--white-tertiary",
  BlackTertiaryButton = "link--black-tertiary",
}

export type AnyLinkProps = {
  to?: string
  clickClass?: string
  className?: string
  children?: ReactNode
  linkStyle?: LinkStyle
  /**
   * Should the element render with a Button instead of an anchor.
   * Setting this to `true` will not use the `true` property but instead the
   * `onClick` handler function
   */
  useButton?: boolean
  /**
   * Click handler for when the `useButton` property is set to `true`. Setting
   * this will also automatically trigger the `e.preventDefault` function before
   * being called
   */
  onButtonClick?: () => void
  /**
   * HTML `type` to be used when `useButton` is true. Default value is `submit`
   */
  buttonType?: "button" | "submit" | "reset"
  /**
   * If the button rendered by `useButton = true` is disabled
   */
  isButtonDisabled?: boolean
  /**
   * Image Source for the icon to be used. Can also work with an Icon Imported
   * as: `import ArrowRight from "wb2-assets/dist/icons/arrow-right.svg"`
   */
  iconSrc?: string
  /**
   * Should the icon be placed on the right side of the CTA
   */
  iconRight?: boolean
  /**
   * Should a CTA have an icon at all
   */
  showIcon?: boolean
}

const lightLinkWithIcon: LinkStyle[] = [
  LinkStyle.WhiteWithChevron,
  LinkStyle.PrimaryButton,
  LinkStyle.WhiteTertiaryButton,
]

const darkLinkWithIcon: LinkStyle[] = [
  LinkStyle.BlackWithChevron,
  LinkStyle.SecondaryButton,
  LinkStyle.SecondaryNoBorderButton,
  LinkStyle.BlackTertiaryButton,
]

const resolveChevron = (
  linkStyle: LinkStyle,
  iconSrc: string,
  iconRight: boolean
) =>
  lightLinkWithIcon.includes(linkStyle) ? (
    iconRight ? (
      <img className="inline link--white__icon--right" src={iconSrc} alt="" />
    ) : (
      <img className="inline link--white__icon--left" src={iconSrc} alt="" />
    )
  ) : darkLinkWithIcon.includes(linkStyle) ? (
    iconRight ? (
      <img className="inline link--black__icon--right" src={iconSrc} alt="" />
    ) : (
      <img className="inline link--black__icon--left" src={iconSrc} alt="" />
    )
  ) : null

const AnyLink: FunctionComponent<AnyLinkProps> = ({
  to = "",
  clickClass = "",
  className = "",
  children,
  linkStyle = LinkStyle.WhiteUnstyled,
  useButton = false,
  onButtonClick,
  buttonType = "submit",
  isButtonDisabled = false,
  iconSrc = ArrowRight,
  iconRight = false,
  showIcon = true,
}) => {
  const chevron = showIcon
    ? resolveChevron(linkStyle, iconSrc, iconRight)
    : null

  if (useButton) {
    return (
      <button
        className={
          `${clickClass} link ${linkStyle} ${className}` +
          (isButtonDisabled === true
            ? " hover:shadow-none focus:shadow-none opacity-75"
            : "")
        }
        onClick={e => {
          e.preventDefault()
          onButtonClick ? onButtonClick() : undefined
        }}
        type={buttonType}
        disabled={isButtonDisabled}
      >
        {!iconRight ? chevron : null}
        {children}
        {iconRight ? chevron : null}
      </button>
    )
  } else {
    return typeof to === "undefined" || to === null || to === "" ? (
      <span className={`${clickClass} link ${linkStyle} ${className}`}>
        {!iconRight ? chevron : null}
        {children}
        {iconRight ? chevron : null}
      </span>
    ) :to.match(/^(http|mailto:|tel:)/) ? (
      <a
        className={`${clickClass} link ${linkStyle} ${className}`}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {!iconRight ? chevron : null}
        {children}
        {iconRight ? chevron : null}
      </a>
    ) : to.startsWith("#") ? (
      <a className={`${clickClass} link ${linkStyle} ${className}`} href={to}>
        {!iconRight ? chevron : null}
        {children}
        {iconRight ? chevron : null}
      </a>
    ) : (
      <Link className={`${clickClass} link ${linkStyle} ${className}`} to={to}>
        {!iconRight ? chevron : null}
        {children}
        {iconRight ? chevron : null}
      </Link>
    )
  }
}

export default AnyLink

export const NavigationLinkFragment = graphql`
  fragment NavigationLinkFragment on StrapiGQLSource_ComponentNavigationLink {
    id
    __typename
    TextMobile
    ClickClassMobile
    TextDesktop
    ClickClassDesktop
    Link
    IsActive
  }
`
