import React, { FunctionComponent } from "react"
import { WBFontStyle } from "../../../types/Fonts"
import { ElementClasses, parseMd } from "../../../utils/parseMd"

export interface ParagraphGroupProps extends ElementClasses {
  /**
   * Markdown string to be converted
   */
  text?: string
  /**
   * Determines whether or not to use aos fade-up animation
   */
  useAnimation?: boolean
  /**
   * `className` for the container element
   */
  className?: string
}

/**
 * Generate paragraphs from string with correct spacing and empty line handline
 */
export const ParagraphGroup: FunctionComponent<ParagraphGroupProps> = ({
  text,
  useAnimation = false,
  className = "text-wb-color-white leading-7",
  p = "mt-xs",
  ul = `mt-xs pl-xs list-square`,
  ol = `mt-xs pl-xs list-decimal`,
  li = ``,
  blockqoute = `mt-xs pl-xs border-l-2 border-wb-color-amg border-solid`,
  h1 = `mt-xs ${WBFontStyle.HeadingXS}`,
  a = `text-wb-color-amg hover:underline`,
  b = `font-bold`,
  blockLevel = ``,
}) => {
  const html = parseMd(text || "", useAnimation, {
    p,
    ul,
    ol,
    li,
    blockqoute,
    h1,
    a,
    b,
    blockLevel,
  })

  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }}></div>
  )
}
