import React, { FunctionComponent, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import AOS from "aos"

import { SiteMetaData } from "../types/SiteMetaData"
import FooterLayout from "./data/FooterLayout/FooterLayout"
import HeaderLayout from "./data/HeaderLayout/HeaderLayout"

type LayoutProps = {
  children?: any
}

type LayoutData = {
  site: {
    siteMetadata: SiteMetaData
  }
}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const data: LayoutData = useStaticQuery(graphql`
    query SiteLayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    AOS.init({
      // animation will start just before element enters screen
      offset: -150,
      // animations should start automatically unless otherwise specified
      delay: 0,
      duration: 500,
      easing: "ease-in-back",
      anchorPlacement: "top-top",
      // animations will only run when scrolling down, not up
      mirror: false,
    })
    return () => {
      //cleanup
    }
  }, [])

  return (
    <div className="bg-wb-color-black flex flex-col min-h-screen">
      <HeaderLayout />
      <main className="flex-grow">{children}</main>
      <FooterLayout />
    </div>
  )
}

export default Layout
