import React, { FunctionComponent } from "react"

type WBGridColumn = {
  className?: string
  largeDesktopSize?: number
  desktopSize?: number
  tabletSize?: number
  smallTabletSize?: number
  mobileSize?: number
}

const resolveSizeClasses = (settings: WBGridColumn): string =>
  `wb-col-mq1-${settings.mobileSize} wb-col-mq2-${settings.smallTabletSize ||
    settings.tabletSize ||
    settings.mobileSize}  wb-col-mq3-${settings.tabletSize ||
    settings.mobileSize} wb-col-mq4-${
    settings.desktopSize
  } wb-col-mq5-${settings.largeDesktopSize || settings.desktopSize}`

const WBGridColumn: FunctionComponent<WBGridColumn> = ({
  mobileSize = 12,
  smallTabletSize,
  tabletSize,
  desktopSize = 12,
  largeDesktopSize,
  className = "",
  children,
}) => (
  <div
    className={`${resolveSizeClasses({
      mobileSize,
      smallTabletSize,
      tabletSize,
      desktopSize,
      largeDesktopSize,
    })} ${className}`}
  >
    {children}
  </div>
)

export default WBGridColumn
