import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { ComponentLayoutParagraph } from "../../../types/generated/strapi"
import Title from "../../generic/Text/Title"
import DoubleCTA from "../../generic/AnyLink/DoubleCTA"
import { G, R, C } from "../../Workbench/Components"
import { ParagraphGroup } from "./ParagraphGroup"
import TitleLayout from "../../generic/Text/TitleLayout"

const Paragraph: FunctionComponent<Partial<ComponentLayoutParagraph>> = ({
  Title: title,
  Text,
  CTAPrimary,
  CTASecondary,
}) => {
  return (
    <G className="mt-xl">
      <R>
        <C desktopSize={8} tabletSize={12} mobileSize={12}>
          {title?.Text || title?.Subtext ? (
            <TitleLayout
              {...title}
              useDefaultAnimation={true}
            />
          ) : null}

          {Text ? (
            <div className={title?.Text ? "mt-m" : ""}>
              <ParagraphGroup text={Text} useAnimation={true} />
            </div>
          ) : null}

          {CTAPrimary || CTASecondary ? (
            <div className="mt-s">
              <DoubleCTA
                primary={CTAPrimary}
                secondary={CTASecondary}
                useDefaultAnimation={true}
              />
            </div>
          ) : null}
        </C>
      </R>
    </G>
  )
}

export default Paragraph

export const ParagraphFragment = graphql`
  fragment ParagraphFragment on StrapiGQLSource_ComponentLayoutParagraph {
    __typename
    _id
    id
    AnchorId
    Text
    Title {
      ...TitleComponentFragment
    }
    CTAPrimary {
      ...NavigationLinkFragment
    }
    CTASecondary {
      ...NavigationLinkFragment
    }
  }
`
