import "./Footer.css"
import React, { FunctionComponent } from "react"
import {
  Footer as FooterModel,
  SocialMedia,
} from "../../../types/generated/strapi"
import AnyLink, { LinkStyle } from "../../generic/AnyLink/AnyLink"
import { LegalLinks } from "../../../types/SiteMetaData"
import SocialIcons from "../../data/FooterLayout/SocialIcons/SocialIcons"

type FooterProps = {
  legalLinks: LegalLinks
  sections?: FooterModel[]
  socialIcons?: SocialMedia
}

const Footer: FunctionComponent<FooterProps> = ({
  legalLinks,
  sections,
  socialIcons,
}) => {
  return (
    <footer className="footer">
      {sections && sections.length > 0 ? (
        <div className="footer__navigation wb-content-grid">
          {socialIcons ? <SocialIcons {...socialIcons} /> : null}
          {sections?.map((section: FooterModel) => (
            <div
              className="wb-col-mq1-12 wb-col-mq3-4 wb-col-mq4-3"
              key={section.id}
            >
              <div className="footer__subheading wb2-type-copy-secondary">
                {section.Name}
              </div>
              <ul className="footer__subnavigation">
                {section.Links?.filter(link => link?.IsActive).map(link => [
                  link?.TextDesktop ? (
                    <li
                      className="footer__link wb2-type-copy-tertiary hidden-small"
                      key={link.id + "d"}
                    >
                      <AnyLink
                        clickClass={link?.ClickClassDesktop}
                        to={link.Link || "/"}
                        linkStyle={LinkStyle.WhiteWithChevron}
                      >
                        {link.TextDesktop}
                      </AnyLink>
                    </li>
                  ) : null,
                  link?.TextMobile ? (
                    <li
                      className="footer__link wb2-type-copy-tertiary hidden-large"
                      key={link.id + "m"}
                    >
                      <AnyLink
                        clickClass={link?.ClickClassMobile}
                        to={link.Link || "/"}
                        linkStyle={LinkStyle.WhiteWithChevron}
                      >
                        {link.TextMobile}
                      </AnyLink>
                    </li>
                  ) : null,
                ])}
              </ul>
            </div>
          ))}
        </div>
      ) : null}
      <div className="footer__legal wb-color-obsidian-bg">
        <div className="wb-content-grid">
          <div className="wb-col-mq1-12">
            <ul className="wb-content-grid__row footer__legal">
              <li className="footer__link wb2-type-copy-tertiary">
                © {new Date().getFullYear()}. Mercedes-Benz South Africa
                Limited. All rights reserved
              </li>
              <li className="footer__link wb2-type-copy-tertiary">
                <AnyLink to={legalLinks?.cookies || ""}>Cookies</AnyLink>
              </li>
              <li className="footer__link wb2-type-copy-tertiary">
                <AnyLink to={legalLinks?.dataProtection || ""}>
                  Data Protection
                </AnyLink>
              </li>
              <li className="footer__link wb2-type-copy-tertiary">
                <AnyLink to={legalLinks?.legalNotice || ""}>
                  Legal Notice
                </AnyLink>
              </li>
              <li className="footer__link wb2-type-copy-tertiary">
                <AnyLink to={legalLinks?.provider || ""}>
                  Provider
                </AnyLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

// © 2019. Mercedes-Benz South Africa Limited. All rights reserved (provider)  Cookies  Data protection  Legal Notice
