import facebook from "wb2-assets/dist/icons/facebook.svg"
import React, { FunctionComponent } from "react"
import AnyLink from "../../../generic/AnyLink/AnyLink"

export type InstagramProps = {
  url?: string
}

const Instagram: FunctionComponent<InstagramProps> = ({ url }) => {
  return (
    <AnyLink to={url}>
      <svg
        className="h-s mr-xs hover:text-wb-color-amg transition-1/5"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <path d="M11 1.982c2.937 0 3.285.011 4.445.064 1.072.049 1.655.228 2.043.379.513.2.88.438 1.265.823.385.385.623.751.823 1.265.151.388.33.97.379 2.043.053 1.16.064 1.508.064 4.445s-.011 3.285-.064 4.445c-.049 1.072-.228 1.655-.379 2.043-.2.513-.438.88-.823 1.265a3.41 3.41 0 0 1-1.265.823c-.388.151-.97.33-2.043.379-1.16.053-1.508.064-4.445.064s-3.285-.011-4.445-.064c-1.072-.049-1.655-.228-2.043-.379-.513-.2-.88-.438-1.265-.823a3.41 3.41 0 0 1-.823-1.265c-.151-.388-.33-.97-.379-2.043-.052-1.161-.063-1.509-.063-4.446s.011-3.285.064-4.445c.049-1.072.228-1.655.379-2.042.2-.513.438-.88.823-1.265a3.41 3.41 0 0 1 1.265-.823c.388-.151.97-.33 2.043-.379 1.159-.053 1.507-.064 4.444-.064M11 0C8.013 0 7.638.013 6.465.066c-1.171.053-1.97.239-2.67.511a5.412 5.412 0 0 0-1.949 1.269A5.412 5.412 0 0 0 .577 3.795c-.272.7-.458 1.499-.511 2.67C.013 7.638 0 8.013 0 11s.013 3.362.066 4.535c.053 1.171.239 1.97.511 2.67a5.382 5.382 0 0 0 1.269 1.948 5.39 5.39 0 0 0 1.948 1.269c.7.272 1.499.458 2.67.511C7.638 21.987 8.013 22 11 22s3.362-.013 4.535-.066c1.171-.053 1.97-.239 2.67-.511a5.382 5.382 0 0 0 1.948-1.269 5.39 5.39 0 0 0 1.269-1.948c.272-.7.458-1.499.511-2.67.054-1.174.067-1.549.067-4.536s-.013-3.362-.066-4.535c-.053-1.171-.239-1.97-.511-2.67a5.382 5.382 0 0 0-1.269-1.948A5.39 5.39 0 0 0 18.206.578c-.7-.272-1.499-.458-2.67-.511C14.362.013 13.987 0 11 0z" />
        <path d="M11 5.351a5.649 5.649 0 1 0 0 11.298A5.649 5.649 0 0 0 11 5.35zm0 9.316a3.667 3.667 0 1 1 0-7.335 3.667 3.667 0 0 1 0 7.335z" />
        <circle cx="16.872" cy="5.128" r="1.32" />
      </svg>
    </AnyLink>
  )
}

export default Instagram
