import React, { FunctionComponent } from "react"
import AnyLink from "../../../generic/AnyLink/AnyLink"

export type LinkedInProps = {
  url?: string
}

const LinkedIn: FunctionComponent<LinkedInProps> = ({ url }) => {
  return (
    <AnyLink to={url}>
      <svg
        className="h-s mr-xs  hover:text-wb-color-amg  transition-1/5"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <path d="M20.354 0H1.622C.727 0 0 .71 0 1.584v18.811c0 .875.727 1.586 1.622 1.586h18.732c.897 0 1.627-.711 1.627-1.586V1.584C21.982.71 21.251 0 20.354 0zM6.519 18.731h-3.26V8.241H6.52v10.49zM4.89 6.807a1.89 1.89 0 1 1 0-3.782 1.89 1.89 0 0 1 0 3.782zm13.841 11.924h-3.258V13.63c0-1.216-.022-2.781-1.694-2.781-1.696 0-1.957 1.325-1.957 2.693v5.189H8.564V8.241h3.127v1.433h.044c.435-.825 1.499-1.694 3.085-1.694 3.301 0 3.911 2.173 3.911 4.997v5.754z" />
      </svg>
    </AnyLink>
  )
}

export default LinkedIn
