export interface ElementClasses {
  /**
   * Represent an HTML `p` element.
   * Classes will be placed after the `blockLevel` property
   */
  p?: string
  /**
   * Represent an HTML `ul` element.
   * Classes will be placed after the `blockLevel` property
   */
  ul?: string
  /**
   * Represent an HTML `ol` element.
   * Classes will be placed after the `blockLevel` property
   */
  ol?: string
  /**
   * Represent an HTML `li` element.
   * Classes will be placed after the `blockLevel` property
   */
  li?: string
  /**
   * Represent an HTML `blockqoute` element.
   * Classes will be placed after the `blockLevel` property
   */
  blockqoute?: string
  /**
   * Represent an HTML `h1` element.
   * Classes will be placed after the `blockLevel` property
   */
  h1?: string
  /**
   * Represent an HTML `a` element.
   * Will not get `blockLevel` classes
   */
  a?: string
  /**
   * Represent an HTML `b` element.
   * Will not get `blockLevel` classes
   */
  b?: string
  /**
   * Classes to be added before element specific classes
   */
  blockLevel?: string
}

/**
 * Parses markdown into an html string
 * Source: https://codepen.io/kvendrik/pen/Gmefv
 * @param md
 */
export const parseMd = (
  md: string,
  useAnimation: boolean = false,
  {
    p = ``,
    ul = ``,
    ol = ``,
    li = ``,
    blockqoute = ``,
    h1 = ``,
    a = ``,
    b = ``,
    blockLevel = ``,
  }
) => {
  const anim = useAnimation ? 'data-aos="fade-up"' : ""

  //font styles
  md = md.replace(/[\*\_]{2}([^\*\_]+)[\*\_]{2}/g, `<b class="${b}">$1</b>`)

  //ul
  md = md.replace(/^\s*\n\*/gm, `<ul class="${blockLevel} ${ul}">\n*`)
  md = md.replace(/^(\*.+)\s*\n([^\*])/gm, "$1\n</ul>\n\n$2")
  md = md.replace(
    /^\*(.+)/gm,
    `<li ${anim} class="${blockLevel} ${li}">$1</li>`
  )

  //ol
  md = md.replace(/^\s*\n\d\./gm, `<ol class="${blockLevel} ${ol}">\n1.`)
  md = md.replace(/^(\d\..+)\s*\n([^\d\.])/gm, "$1\n</ol>\n\n$2")
  md = md.replace(
    /^\d\.(.+)/gm,
    `<li ${anim} class="${blockLevel} ${li}">$1</li>`
  )

  //blockquote
  md = md.replace(
    /^\>(.+)/gm,
    `<blockquote ${anim} class="${blockLevel} ${blockqoute}">$1</blockquote>`
  )

  //h
  // md = md.replace(/[\#]{6}(.+)/g, `<h6 class=${className.h6}>$1</h6>`)
  // md = md.replace(/[\#]{5}(.+)/g, `<h5 class=${className.h5}>$1</h5>`)
  // md = md.replace(/[\#]{4}(.+)/g, `<h4 class=${className.h4}>$1</h4>`)
  // md = md.replace(/[\#]{3}(.+)/g, `<h3 class=${className.h3}>$1</h3>`)
  // md = md.replace(/[\#]{2}(.+)/g, `<h2 class=${className.h2}>$1</h2>`)
  md = md.replace(
    /[\#]{1}(.+)/g,
    `<h3 ${anim} class="${blockLevel} ${h1}">$1</h3>`
  )

  //alt h
  // md = md.replace(/^(.+)\n\=+/gm, `<h1 class=${className.h1}>$1</h1>`)
  // md = md.replace(/^(.+)\n\-+/gm, `<h2 class=${className.h2}>$1</h2>`)

  //images
  // md = md.replace(/\!\[([^\]]+)\]\(([^\)]+)\)/g, '<img src="$2" alt="$1" />')

  //links
  md = md.replace(
    /[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g,
    `<a href="$2" title="$4" class="${a}" target="_blank" rel="noopener noreferrer">$1</a>`
  )

  // md = md.replace(/[\*\_]{1}([^\*\_]+)[\*\_]{1}/g, "<i>$1</i>")
  // md = md.replace(/[\~]{2}([^\~]+)[\~]{2}/g, "<del>$1</del>")

  //pre
  // md = md.replace(/^\s*\n\`\`\`(([^\s]+))?/gm, '<pre class="$2">')
  // md = md.replace(/^\`\`\`\s*\n/gm, "</pre>\n\n")

  //code
  // md = md.replace(/[\`]{1}([^\`]+)[\`]{1}/g, "<code>$1</code>")

  //p
  md = md.replace(/^\s*(\n)?(.+)/gm, function(m) {
    return /\<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m)
      ? m
      : `<p ${anim} class="${blockLevel} ${p}">` + m + "</p>"
  })

  //strip p from pre
  // md = md.replace(/(\<pre.+\>)\s*\n\<p\>(.+)\<\/p\>/gm, "$1$2")

  return md
}
