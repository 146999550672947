import "./Title.css"
import React, { FunctionComponent } from "react"
import { WBFontStyle } from "../../../types/Fonts"
import { WBColorStyle } from "../../../types/Colours"
import { ENUM_COMPONENTCONTENTTITLE_HEADINGSIZE as HeadingSize } from "../../../types/generated/strapi"
import optionalAnimationAttributes from "../../../utils/optionalAnimationAttributes"
import ResolveTitle from "./ResolveTitleSize"

export type TitleProps = {
  text?: string
  textStyle?: WBFontStyle
  subtext?: string
  subtextStyle?: WBFontStyle
  hasOverline?: boolean
  textColor?: WBColorStyle | string
  className?: string
  /*
   * AOS Animation Value, fade-up, fade-down, etc.
   */
  animationType?: string
  /**
   * Whether or not to use the default AOS Animation
   */
  useDefaultAnimation?: boolean
  /**
   * Any additional delay to be applied to the animations,
   * useful if part of an animated parent component
   */
  animationDelayOffset?: number
  headingSize?: HeadingSize
}

const Title: FunctionComponent<TitleProps> = ({
  text,
  textStyle = WBFontStyle.HeadingL,
  subtext,
  subtextStyle = WBFontStyle.CopySecondary,
  hasOverline = true,
  textColor = WBColorStyle.White,
  className = "",
  animationType = undefined,
  useDefaultAnimation = false,
  animationDelayOffset = undefined,
  headingSize = HeadingSize.HeadingL,
}) => {
  if (useDefaultAnimation) animationType = "fade-right"

  return (
    <div className={`title ${textColor} ${className}`}>
      {subtext ? (
        <h3
          {...optionalAnimationAttributes(animationType, animationDelayOffset)}
          className={`title__subtext ${subtextStyle}`}
        >
          {subtext}
        </h3>
      ) : null}
      {hasOverline ? (
        <div
          {...optionalAnimationAttributes(
            animationType,
            animationDelayOffset && animationDelayOffset + 50
          )}
          className="title__overline"
        ></div>
      ) : null}
        <ResolveTitle
          animationType={animationType}
          animationDelayOffset={animationDelayOffset}
          text={text}
          textStyle={textStyle}
          heading={headingSize}
        />
    </div>
  )
}
export default Title
