import React, { FunctionComponent } from "react"
import AnyLink, { LinkStyle } from "./AnyLink"

export type ResponsiveLinkProps = {
  to: string
  linkStyle?: LinkStyle
  clickClassDesktop?: string
  clickClassMobile?: string
  linkTextDesktop?: string
  linkTextMobile?: string
  className?: string
  desktopVisibleStyle?: LinkDisplay
  mobileVisibleStyle?: LinkDisplay
}

export enum LinkDisplay {
  block = "block",
  inlineBlock = "inline-block",
  inline = "inline",
}

const ResponsiveLink: FunctionComponent<ResponsiveLinkProps> = ({
  to,
  linkStyle,
  linkTextDesktop,
  linkTextMobile,
  clickClassDesktop = "",
  clickClassMobile = "",
  className = "",
  desktopVisibleStyle = LinkDisplay.inline,
  mobileVisibleStyle = LinkDisplay.inline,
}) => {
  let desktopVisibleClass: string = convertDesktopClass(desktopVisibleStyle)

  return (
    <>
      {linkTextDesktop ? (
        <AnyLink
          to={to}
          clickClass={clickClassDesktop}
          className={`${className} hidden ${desktopVisibleClass}`}
          linkStyle={linkStyle}
        >
          {linkTextDesktop}
        </AnyLink>
      ) : null}

      {linkTextMobile ? (
        <AnyLink
          to={to}
          clickClass={clickClassMobile}
          className={`${className} ${mobileVisibleStyle} lg:hidden`}
          linkStyle={linkStyle}
        >
          {linkTextMobile}
        </AnyLink>
      ) : null}
    </>
  )
}

export default ResponsiveLink
function convertDesktopClass(desktopVisibleStyle: LinkDisplay) {
  let desktopVisibleClass: string
  switch (desktopVisibleStyle) {
    case LinkDisplay.block:
      desktopVisibleClass = "lg:block"
      break
    case LinkDisplay.inlineBlock:
      desktopVisibleClass = "lg:inline-block"
      break
    case LinkDisplay.inline:
      desktopVisibleClass = "lg:inline"
      break
  }
  return desktopVisibleClass
}
