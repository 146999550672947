import { graphql } from "gatsby"
import React from "react"
import { FunctionComponent } from "react"
import { WBColorStyle } from "../../../types/Colours"
import { WBFontStyle } from "../../../types/Fonts"
import {
  ComponentContentTitle,
  ENUM_COMPONENTCONTENTTITLE_HEADINGSIZE as hSize,
} from "../../../types/generated/strapi"
import Title from "./Title"
import tOWHeadingSize from "./toOWHeadingSize"

export interface TitleLayoutProps extends ComponentContentTitle {
  hasOverline?: boolean
  textColor?: WBColorStyle
  subtextStyle?: WBFontStyle
  useDefaultAnimation?: boolean
}

const TitleLayout: FunctionComponent<TitleLayoutProps> = ({
  Text,
  Subtext,
  HeadingSize = hSize.HeadingL,
  hasOverline,
  textColor,
  subtextStyle,
  useDefaultAnimation
}) => {
  
  return (
    <>
      <Title
        text={Text}
        subtext={Subtext}
        textStyle={tOWHeadingSize(HeadingSize)}
        headingSize={HeadingSize}
        hasOverline={hasOverline}
        textColor={textColor}
        subtextStyle={subtextStyle}
        useDefaultAnimation={useDefaultAnimation}
      />
    </>
  )
}
export default TitleLayout

export const TitleComponentFragment = graphql`
  fragment TitleComponentFragment on StrapiGQLSource_ComponentContentTitle {
    _id
    id
    __typename
    Text
    Subtext
    HeadingSize
  }
`
