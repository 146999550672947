import "./Header.css"
import React, { FunctionComponent, useState } from "react"
import Navigation from "./Navigation/Navigation"
import Logo from "./Logo/Logo"
import { Navigation as NavigationModel } from "../../../types/generated/strapi"
import ResponsiveLink from "../../generic/AnyLink/ResponsiveLink"
import AnyLink from "../../generic/AnyLink/AnyLink"

type HeaderProps = {
  navSections?: NavigationModel[]
}

const Header: FunctionComponent<HeaderProps> = ({ navSections }) => {
  const [isMobileMenuOpen, setMobileMenuOpenState] = useState<boolean>(false)

  return (
    <header className="header z-50">
      <div className="wb-content-grid">
        <div className="header__wrapper">
          <Logo
            toggleMenuState={() => setMobileMenuOpenState(!isMobileMenuOpen)}
          />
          <Navigation
            isMenuOpen={isMobileMenuOpen}
            sections={navSections || []}
          />
        </div>
      </div>
    </header>
  )
}
export default Header
