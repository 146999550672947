import "./Navigation.css"
import React, { FunctionComponent, useState } from "react"
import AnyLink, { LinkStyle } from "../../../generic/AnyLink/AnyLink"
import { Navigation as NavigationModel } from "../../../../types/generated/strapi"
import ArrowDown from "wb2-assets/dist/icons/arrow-down.svg"
import ResponsiveLink, {
  LinkDisplay,
} from "../../../generic/AnyLink/ResponsiveLink"

type NavigationProps = {
  sections?: NavigationModel[]
  isMenuOpen?: boolean
}

const Navigation: FunctionComponent<NavigationProps> = ({
  sections,
  isMenuOpen = false,
}) => {
  return (
    <nav
      className={["navigation", isMenuOpen ? "navigation--open" : ""].join(" ")}
      role="navigation"
    >
      {sections?.map(section => (
        <ul className="navigation__menu" key={section.id}>
          <li className="navigation__submenu">
            <div className="navigation__subheading">
              <div className="navigation__subtext">
                {section.Name}
                <img className="navigation__arrow" src={ArrowDown} />
              </div>
              <div className="navigation__subbackground"></div>
            </div>
            <ul className="navigation__links">
              {section?.Links?.map(link => (
                <li key={link?.id} className="navigation__item">
                  <ResponsiveLink
                    to={link?.Link || "#"}
                    linkStyle={LinkStyle.WhiteUnstyled}
                    linkTextDesktop={link?.TextDesktop}
                    linkTextMobile={link?.TextMobile}
                    clickClassDesktop={link?.ClickClassDesktop}
                    clickClassMobile={link?.ClickClassMobile}
                    desktopVisibleStyle={LinkDisplay.block}
                    mobileVisibleStyle={LinkDisplay.block}
                    className={"navigation__link"}
                  />
                </li>
              ))}
            </ul>
          </li>
        </ul>
      ))}
    </nav>
  )
}

export default Navigation
