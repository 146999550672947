import WBGrid from "./WBGrid"
import WBGridColumn from "./WBGridColumn"
import WBGridRow from "./WBGridRow"
import WBParagraph from "./WBParagraph"

export const G = WBGrid
export const R = WBGridRow
export const C = WBGridColumn

export const P = WBParagraph
