import { SocialMedia } from "../../../../types/generated/strapi"
import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import Facebook from "./Facebook"
import Pinterest from "./Pinterest"
import Instagram from "./Instagram"
import Twitter from "./Twitter"
import YouTube from "./Youtube"
import LinkedIn from "./Linkedin"

const SocialIcons: FunctionComponent<Partial<SocialMedia>> = ({
  Facebook: facebook,
  LinkedIn: linkedIn,
  Pinterest: pinterest,
  Instagram: instagram,
  Twitter: twitter,
  Youtube: youtube,
}) => {
  return (
    <div className="flex mb-s">
      {facebook ? <Facebook url={facebook} /> : null}
      {linkedIn ? <LinkedIn url={linkedIn} /> : null}
      {pinterest ? <Pinterest url={pinterest} /> : null}
      {instagram ? <Instagram url={instagram} /> : null}
      {twitter ? <Twitter url={twitter} /> : null}
      {youtube ? <YouTube url={youtube} /> : null}
    </div>
  )
}

export default SocialIcons

export const SocialIconsFragment = graphql`
  fragment SocialIconsFragment on StrapiGQLSource_SocialMedia {
    __typename
    _id
    id
    Facebook
    LinkedIn
    Pinterest
    Instagram
    Twitter
    Youtube
  }
`
